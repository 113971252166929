<template>
	<div>
		<a-table :rowKey="rowKey" :loading="loading" :columns="columns" :dataSource="isRequest ? list : dataList"
			:pagination="isPage ? pagination : false" :scroll="scroll" :customRow="customRow" :size="size" :rowClassName="rowClassName"
			:bordered="bordered" @resizeColumn="handleResizeColumn" @change="changeSort"
			:rowSelection="rowSelectionType ? { type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll } : null">
			<template #headerCell="cell">
				<slot name="headerCell" v-bind="cell"></slot>
			</template>
			<template #bodyCell="cell">
				<slot name="bodyCell" v-bind="isPage ? {...cell, pagination: pagination } : cell"></slot>
			</template>
			<template #footerCell="cell">
				<slot name="footerCell" v-bind="cell"></slot>
			</template>
			<template #summary>
				<slot name="summary"></slot>
			</template>
		</a-table>
	</div>
</template>

<script>
	export default {
		emits: ['rowSelected', 'dragEnd', 'pageChange'],
		props: {
			columns: {
				type: Array,
				default: () => []
			},
			dataList: {
				type: Array,
				default: () => []
			},
			searchData: {
				type: Object,
				default: () => {}
			},
			isRequest: {
				type: Boolean,
				default: false
			},
			requestFun: {
				type: Function,
				default: () => {}
			},
			totalCount: {
				type: Number,
				default: 0
			},
			scroll: {
				default: Object,
				default: () => {}
			},
			draggable: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'middle'
			},
			bordered: {
				type: Boolean,
				default: false
			},
			rowSelectionType: { // radio   checkbox
				type: String,
				default: ''
			},
			isPage: {
				type: Boolean,
				default: true
			},
			rowKey: {
				type: String,
				default: 'id'
			},
			rowClassName:{
				type: Function,
				default: () => {}
			}
		},
		data() {
			return {
				loading: false,
				list: [],
				selectedRowKeys: [],
				selectedRowData: [],
				sourceIndex: -1,
				sorterList:[],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			if (this.isRequest) {
				// this.getData();
			}
		},
		update() {
			if (!this.isRequest) {
				this.pagination.total = this.totalCount || this.dataList.length;
			}
		},
		methods: {
			async getData() {
				if (!this.isRequest) {
					this.$emit('pageChange', {
						page: this.pagenation.current,
						pageSize: this.pagenation.pageSize,
					})
					return;
				};
				this.loading = true;
				try {
					let ret = await this.requestFun({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = [];
						// console.log("ctable")
						this.pagination.total = ret.data.totalCount
						this.list = ret.data.list || ret.data;
						this.$nextTick(() => {
							this.$forceUpdate();
						});
						// if (this.list[0].extra) {
						// 	this.list.forEach((item, index) => {
						// 		this.list[index].extra = JSON.parse(this.list[index].extra)
						// 	})
						// }
					}
				} catch(e) {
					this.loading = false;
				}
			},
			toQuery() {
				this.pagination.current = 1;
				this.getData();
			},
			handleResizeColumn(w, col) {
				col.width = w;
			},
			customRow(record, index) {
				return {
					draggable: this.draggable,
					style: {
						cursor: this.draggable ? 'pointer' : '',
						'font-size': '12px'
					},
					onDragstart: (event) => {
						if (!this.draggable) return;
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到源目标数据
						this.sourceIndex = index;
					},
					onDragover: (event) => {
						if (!this.draggable) return;
						// 兼容 IE
						var ev = event || window.event
						// 阻止默认行为
						// this.sourceIndex = -1;
						ev.preventDefault()
					},
					onDrop: (event) => {
						if (!this.draggable) return;
						if (this.sourceIndex === -1) return;
						// 兼容IE
						var ev = event || window.event
						// 得到目标数据
						// this.targetObj = record;
						if (this.isRequest) {
							let itemData = this.list.splice(this.sourceIndex, 1);
							if (itemData) {
								this.list.splice(index, 0, itemData[0]);
							}
							this.$emit('dragEnd', this.list);
						} else {
							let itemData = this.dataList.splice(this.sourceIndex, 1);
							this.dataList.splice(index, 0, itemData[0]);
							this.$emit('dragEnd', this.dataList);
						}
						this.sourceIndex = -1;
						// 阻止冒泡
						ev.stopPropagation()
					}
				}
			},
			onSelectChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedRowData = this.selectedRowData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedRowData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedRowData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
				this.$emit('rowSelected', {
					keys: this.selectedRowKeys,
					list: this.selectedRowData
				});
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
				this.$emit('rowSelected', {
					keys: this.selectedRowKeys,
					list: this.selectedRowData
				});
			},
			//调接口排序
			changeSort(page, filters, sorter,extra){
				if(extra.action == 'sort'){
					this.$emit('changeSort',sorter?.order || null,sorter.columnKey);
				}
			}
		}
	}
</script>

<style scoped>
</style>
