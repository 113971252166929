<template>
	<span>
		<span @click="onVisible">
			<slot>
				<a-button :disabled="disabled" type="primary" ghost>导出</a-button>
			</slot>
		</span>
		<a-modal v-model:visible="exportVisible" title="创建导出任务" @ok="onExportOk" @cancel="onCancel">
			<a-spin :spinning="loading">
				<a-form>
					<a-form-item label="任务名称">
						<a-input v-model:value="title" placeholder="请输入"/>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import { getTaskExport } from '@/service/modules/importAndExport.js';
	export default {
		props: {
            type: {
                type: String,
                default: ''
            },
			searchData: {
				type: Object,
				default: ()=> {
					return {}
				}
			},
			condition: {
			    type: String,
			    default: ''
			},
			role: {
			    type: Number,
			    default: ''
			},
			disabled: {
				type: Boolean,
				default: false
			},
			isAll: { // 查询条件全导出或选择导出
				type: Number,
				default: false
			},
		},
		data() {
			return {
                title: '',
                loading: false,
                exportVisible: false,
            }
		},
		methods: {
			onVisible() {
				if (this.disabled) return;
				this.exportVisible = true;
			},
			async onExportOk() {
				if (!this.title.trim()) {
					this.$message.warn('请输入任务名称');
                    return;
				}
				this.loading = true;
				let ret = null;
				try {
					let ret = await getTaskExport({
						title: this.title,
						type: this.type,
						condition: this.condition,
						role: this.role,
						isAll: this.isAll,	
						param: JSON.stringify(this.searchData)
					});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('导出任务创建成功，请前往【系统-任务中心-导出任务列表】查看进度详情并下载');
						this.title = '';
                        this.exportVisible = false;
                        // this.$emit('hiddenVisible',false);
					}
				} catch(e) {
					this.loading = false;
				}
			},
            onCancel() {
				this.title = ''
                this.exportVisible = false;
                // this.$emit('hiddenVisible',false);
            }
		}
	}
</script>
