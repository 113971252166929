import { send } from "../index";
// 导出
export function getTaskExport(data, opts = {}) {
  return send({
    url: "/admin/taskExport/save.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 导入
export function getTaskImport(data, opts = {}) {
  return send({
    url: "/admin/taskImport/save.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 导出任务列表
export function getTaskExportList(data, opts = {}) {
  return send({
    url: "/admin/taskExport/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 导入任务列表
export function getTaskImportList(data, opts = {}) {
  return send({
    url: "/admin/taskImport/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

// 下载模本文件
export function getTemplateFile(data, opts = {}){
  return send({
    url: "/admin/taskImport/module.do",
    method: "POST",
    // responseType:'blob',
    data,
    ...opts,
  });
}